@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
body{margin:0;font-family:"Poppins", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#282c34}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.popup {
  position: fixed;
  text-transform: uppercase;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 40%;
  white-space: nowrap;
  padding: 0 1em;
  font-family: inherit;
  font-weight: bolder;
  font-size: 2.5em;
  border-color: #e6e7e8;
  border-style: double;
  border-width: 0.4em;
  z-index: 99;
}

.accepted {
  background-color: green;
}

.accepted h2 {
  -webkit-animation: blinker 2s linear infinite;
          animation: blinker 2s linear infinite;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.rejected {
  background-color: red;
}

.rejected h2 {
  -webkit-animation: blinker 0.5s linear infinite;
          animation: blinker 0.5s linear infinite;
}

body {
  color: #e6e7e8;
}

.outer {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.countdown {
  position: absolute;
}

.separator {
  padding: 10px;
}

.passcode {
  height: auto;
  font-size: 125px;
}

.passcode-wrapper {
  display: flex;
  min-height: 100vh;
  justify-content: center;
}

.top{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:100px}h3{position:fixed;top:10px}.email{padding:5px;border:1px solid;border-color:#000000;border-radius:5px;opacity:0.8;max-width:50vw}:root{--color-red: #ec1840;--color-green: #00be5f;--color-white: #fff;--color-black-1: #111;--color-black-2: #222;--color-black-3: #444;--speed-normal: 0.5s;--speed-fast: 0.8s}*{margin:0;padding:0;box-sizing:border-box;font-family:"Poppins", sans-serif}.fancy-button{position:relative;background-color:#282c34;width:180px;height:60px;margin:20px;line-height:60px;letter-spacing:2px;text-decoration:none;text-transform:uppercase;text-align:center;color:#fff;color:var(--color-white);transition:0.5s;transition:var(--speed-normal);border:1px solid #ec1840;border:1px solid var(--color-red);cursor:pointer}.fancy-button.green{border:1px solid #00be5f;border:1px solid var(--color-green)}.fancy-button:hover{border:1px solid transparent;background:#ec1840 url(https://i.postimg.cc/wBXGXbWN/pixel.png);background:var(--color-red) url(https://i.postimg.cc/wBXGXbWN/pixel.png);transition-delay:0.8s;background-size:180px;-webkit-animation:animate 0.8s steps(8) forwards;animation:animate 0.8s steps(8) forwards;-webkit-animation:animate var(--speed-fast) steps(8) forwards;animation:animate var(--speed-fast) steps(8) forwards}.fancy-button:hover.green:hover{background:#00be5f;background:var(--color-green);transition-delay:0s;-webkit-animation:animate 0.8s forwards;animation:animate 0.8s forwards;-webkit-animation:animate var(--speed-fast) forwards;animation:animate var(--speed-fast) forwards}@-webkit-keyframes animate{0%{background-position-y:0}100%{background-position-y:-480px}}@keyframes animate{0%{background-position-y:0}100%{background-position-y:-480px}}

