body {
  color: #e6e7e8;
}

.outer {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.countdown {
  position: absolute;
}
