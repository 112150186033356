.popup {
  position: fixed;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-50%);
  top: 40%;
  white-space: nowrap;
  padding: 0 1em;
  font-family: inherit;
  font-weight: bolder;
  font-size: 2.5em;
  border-color: #e6e7e8;
  border-style: double;
  border-width: 0.4em;
  z-index: 99;
}

.accepted {
  background-color: green;
}

.accepted h2 {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.rejected {
  background-color: red;
}

.rejected h2 {
  animation: blinker 0.5s linear infinite;
}
