@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px;
}

h3 {
  position: fixed;
  top: 10px;
}

.email {
  padding: 5px;
  border: 1px solid;
  border-color: #000000;
  border-radius: 5px;
  opacity: 0.8;
  max-width: 50vw;
}

:root {
  --color-red: #ec1840;
  --color-green: #00be5f;
  --color-white: #fff;
  --color-black-1: #111;
  --color-black-2: #222;
  --color-black-3: #444;
  --speed-normal: 0.5s;
  --speed-fast: 0.8s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.fancy-button {
  position: relative;
  background-color: #282c34;
  width: 180px;
  height: 60px;
  margin: 20px;
  line-height: 60px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-white);
  transition: var(--speed-normal);
  border: 1px solid var(--color-red);
  &.green {
    border: 1px solid var(--color-green);
  }
  cursor: pointer;
  &:hover {
    border: 1px solid transparent;
    background: var(--color-red) url(https://i.postimg.cc/wBXGXbWN/pixel.png); // 360px x 1080px
    &.green:hover {
      background: var(--color-green);
      transition-delay: 0s;
      animation: animate var(--speed-fast) forwards;
    }
    transition-delay: 0.8s;
    background-size: 180px;
    animation: animate var(--speed-fast) steps(8) forwards;
  }
}

@keyframes animate {
  0% {
    background-position-y: 0;
  }
  100% {
    background-position-y: -480px;
  }
}
